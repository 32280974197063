.divA {
    /* background-position: center top, right top; */
    background-repeat: no-repeat;
    /* height: 200%; */
    /* background-image: linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url(../../img/nebraska-bcg.png); */
    }
    
    
    .divB {
        /* background-image: url(../../img/header.png); */
        background-repeat: no-repeat;
        height: 6em;
    }
    
    .divC {
        /* background-image: url(../../img/nebraska-txt.png); */
        background-position-y: 10px;
        background-repeat: no-repeat;
        height: 6em;
    }
    
    .divD {
        /* background-image: url(../../img/footer.png); */
        background-position-y: -10px;
        background-repeat: no-repeat;
        height: 6em;
    }