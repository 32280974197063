.external-filters-elem {
    flex: 1;
    margin: 10px;
  }
  
  .external-filters-action-right {
    margin-left: auto;
  }
  
  /*mobile*/
  @media screen and (max-width: 780px) {
    .external-filters-elem {
      max-width: 100% !important;
    }
  
    .external-filters-action {
      margin-top: 10px;
      margin-bottom: 1.45rem;
    }
  
    .external-filters-action>* {
      flex: 0 0 100%;
    }
  
    .external-filters-action>.OkButton {
      align-items: 'center';
      margin-left: -2px;
    }
  }

  .row1 {
    display: flex;
    width: 70%;
    /* align-items: center; */
    margin-left: 250px;
  }

  .details-block {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.details-block h3 {
  text-align: left; 
  width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
}

.line {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.field {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.field div:first-child {
  font-weight: bold;
}

.grid-header-custom-style {
  font-size: 16px;
  color: #0f0f10
  }