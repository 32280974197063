@tailwind base;
@tailwind components;
@tailwind utilities;

.modalRoot {
  display: block;
}


.d-block {
  display: block;
}

p {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}


.loading-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-right: 10px;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.header {
  background: linear-gradient(90deg, #002633, #00607f 57.29%);
  background-image: linear-gradient(90deg, rgb(0, 38, 51), rgb(0, 96, 127) 57.29%);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  color: #f9f9f9;
  text-align: center;
  text-decoration: none;
  /* cursor: pointer; */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

}

.landing-heading-title {

  font-family: 'Roboto', sans-serif;
  color: #0f0f10;
  word-wrap: break-word;
  white-space: normal;
  font-size: 40px;
  font-weight: bold;
  max-height: 280px;
}

input:disabled {
  opacity: 0.7;
  background-color: rgb(206, 206, 206);
  cursor: no-drop;
}

select:disabled {
  opacity: 0.7;
  background-color: rgb(206, 206, 206);
  cursor: no-drop;
}


.asteriskInput::after {
  content: " *";
  color: #e32;
  position: absolute;
  top: -10px;
  margin-left: 5px;
  font-size: xx-large;

}

.button-disabled {
  background-color: #ccc;
  /* Gray background */
  color: #666;
  /* Darker text color for better contrast */
}

.errorMessage {
  color: red;
}





.usa-mandatory-label {
  color: #9a2125;
  margin-left: 3px;
}

.usa-input-group-readonly {
  border-style: none
}

.headerbottombar {
  width: 100%;
  height: 12px;
  background-color: #ffc843;

}


.footer {
  width: 100%;
  height: 2.0rem;
  background-color: rgb(136 136 136);
  color: white;
  text-align: center;
  font-size: 16px;
  line-height: 1.75rem;
}

.header {
  overflow: hidden;
  /* background-color: #fff; */
  background: linear-gradient(90deg, #002633, #00607f 57.29%);
  background-image: linear-gradient(90deg, rgb(0, 38, 51), rgb(0, 96, 127) 57.29%);
  background-position-x: initial;
  background-position-y: initial;
  background-size: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: initial;
  height: 94px;
}

.header a {
  float: left;
  color: #f9f9f9;
  text-align: center;
  text-decoration: none;
  font-size: 50px;
  line-height: 25px;
  cursor: pointer;
  margin-top: 30px;
  margin-left: 50px;
  text-align: center;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.external-filters-action {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}