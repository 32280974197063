.external-filters-elem {
    flex: 1;
    margin: 10px;
  }
  
  .external-filters-action-right {
    margin-left: auto;
  }
  
  /*mobile*/
  @media screen and (max-width: 780px) {
    .external-filters-elem {
      max-width: 100% !important;
    }
  
    .external-filters-action {
      margin-top: 10px;
      margin-bottom: 1.45rem;
    }
  
    .external-filters-action>* {
      flex: 0 0 100%;
    }
  
    .external-filters-action>.OkButton {
      align-items: 'center';
      margin-left: -2px;
    }
  }

  .row1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; /* Adjust this as needed */
    margin: 0 auto; /* Center the row */
    width: 80%; /* Adjust the width as needed */
}

/* Mobile devices */
@media (max-width: 767px) {
  .row1 {
      display: flex;
      flex-direction: column;
  }
  div.row1 > div.element { /* Increase specificity */
    width: 100%; /* Make each element take full width */
    margin-bottom: 10px; /* Adjust bottom spacing as needed */
  }
}
/* Tablets and desktops */
@media (min-width: 768px) {
    .row1 {
        flex-direction: row;
    }
    .row1 .element {
        width: calc(50% - 10px); /* Adjust width as needed, accounting for any margin/padding */
    }
}

  .details-block {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.details-block h3 {
  text-align: left; 
  width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
}

.line {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.field {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.field div:first-child {
  font-weight: bold;
}

.element {
  box-sizing: border-box;
  flex-basis: calc(50% - 10px);
  margin-bottom: 20px;
}

.action-btns {
  padding-right: 1.5rem;
}

.OkButton {
  float: right;
  margin-bottom: 20px;
  margin-left: 10px;
}

.my-editor {
  border: 1px solid #3e3e3f;
  max-width: 30rem;
  width: 100%;
  margin-top: 9px;
  overflow-y: auto;
}

.greyed-out {
  opacity: 0.7;
  background-color: rgb(206, 206, 206);
  cursor: no-drop;
}