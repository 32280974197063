.divA {
/* background-position: center top, right top; */
background-repeat: no-repeat;
/* height: 200%; */
/* background-image: linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7)), url(../../img/nebraska-bcg.png); */
}


.divB {
    /* background-image: url(../../img/header.png); */
    background-repeat: no-repeat;
    height: 6em;
}

.divC {
    /* background-image: url(../../img/nebraska-txt.png); */
    background-position-y: 10px;
    background-repeat: no-repeat;
    height: 6em;
}

.divD {
    /* background-image: url(../../img/footer.png); */
    background-position-y: -10px;
    background-repeat: no-repeat;
    height: 6em;
}

.logout_button {
    margin-top: 5px !important;
    float: right;
    margin-top: 15px;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
    .logout_button {
        margin-top: 5px !important;
    }
}

.home_page_css {
    margin: 30px !important;
}


  .loading-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .loading-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
    display: inline-block;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.page-subtitle-container {
    flex: 1;
    text-align: center;
    align-items: center;
    display: flex;
    width: 100%;
    margin-right: -200px;
}

.dropdown-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
}

@media (max-width: 900px) {
    .header-container {
        flex-direction: column;
        align-items: flex-start;
    }

    .header-container > * {
        width: 100%;
        margin-bottom: 10px;
    }

    .page-subtitle-container {
        text-align: left;
    }

    .dropdown-container {
        justify-content:center;
    }
}