.external-filters-elem {
    flex: 1;
    margin: 10px;
  }
  
  .external-filters-action {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  
  .external-filters-action-right {
    margin-left: auto;
  }
  
  /*mobile*/
  @media screen and (max-width: 780px) {
    .external-filters-elem {
      max-width: 100% !important;
    }
  
    .external-filters-action {
      margin-top: 10px;
      margin-bottom: 1.45rem;
    }
  
    .external-filters-action>* {
      flex: 0 0 100%;
    }
  
    .external-filters-action>.OkButton {
      align-items: 'center';
      margin-left: -2px;
    }
  }

  .row1 {
    display: flex;
    width: 70%;
    /* align-items: center; */
    margin-left: 250px;
  }

  .details-block {
    padding: 20px;
    margin: 20px 0;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.details-block h3 {
  text-align: left; 
  width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
}

.line {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.field {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.field div:first-child {
  font-weight: bold;
}

.pointer-cursor-row:hover {
  cursor: pointer;
}

.file-link {
  color: rgb(62, 62, 159);
  text-decoration: underline;
  font-weight: bold;
}

.row1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto; 
  width: 80%;
}

/* Mobile devices */
@media (max-width: 767px) {
.row1 {
    display: flex;
    flex-direction: column;
}
div.row1 > div.element { 
  width: 100%; 
  margin-bottom: 10px;
}
}
/* Tablets and desktops */
@media (min-width: 768px) {
  .row1 {
      flex-direction: row;
  }
  .row1 .element {
      width: calc(50% - 10px);
      margin-bottom: auto;
  }
}
.greyed-out {
  opacity: 0.7;
  background-color: rgb(206, 206, 206);
  cursor: no-drop;
}

.grid-header-custom-style {
font-size: 16px;
color: #0f0f10
}