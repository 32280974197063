.multi-select-dropdown {
    position: relative; /* Makes this the reference point for the dropdown options */
  }
  
  .dropdown-options {
    position: absolute;
    /* top: 100%; 
    left: 0;
    width: 100%;  */
    z-index: 999; 
    background: white; 
    border: 1px solid; 
  }